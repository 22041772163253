import { InitalizeApp, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyCk6UTCVAP_NBFHYpZCSuQA8D7jhBOryjs",
  authDomain: "ryansmeats-697f9.firebaseapp.com",
  projectId: "ryansmeats-697f9",
  storageBucket: "ryansmeats-697f9.appspot.com",
  messagingSenderId: "637964071752",
  appId: "1:637964071752:web:88dd7c1a81872b4441b97d"
};
  // initallize app

  initializeApp(firebaseConfig)

  // get firestore

  const db = getFirestore()

  // get auth

  const auth = getAuth()


// get storage

const storage = getStorage()

// get functions

const functions = getFunctions()




export { db, auth, storage, functions}


