import React from 'react'

//styles

import "./Footer.css"

export default function Footer() {
  return (
    <div className="footer">
    </div>
  )
}
